<template>
    <div class="filter-container">
        <div class="field-column field-column__xlarge">
            <div class="field-container">
                <span class="field-label">Date:</span>
                <date-picker v-model="range" range></date-picker>
            </div>
            <div class="field-container">
                <span class="field-label">Experience: </span>
                <v-select
                    v-model="experience"
                    :options="sortedExperiences"
                    :clearable="false"
                    label="name"
                ></v-select>
            </div>
        </div>
        <div class="field-column field-column__xlarge">
            <div class="field-container">
                <span class="field-label">Location: </span>
                <v-select
                    v-model="selectedLocation"
                    :options="sortedLocations"
                    :clearable="false"
                    label="name"
                >
                </v-select>
            </div>
            <div class="field-container">
                <span class="field-label">X-Cube: </span>
                <v-select
                    v-model="selectedXCube"
                    :options="sortedXCubes"
                    :clearable="false"
                    label="display"
                >
                </v-select>
            </div>
        </div>
        <div class="field-column">
            <div class="field-container">
                <span class="field-label__large">Total Players: </span>
                <div>{{ playerSum }}</div>
            </div>

            <div class="field-container">
                <span class="field-label__large">Average Rating: </span>
                <div>{{ Number(averageRating).toFixed(1) || "N/A" }}</div>
            </div>
            <div v-if="selectedLocation.id !=='all'" class="field-container">
                <span class="field-label__large">Average Location Rating: </span>
                <div>{{ Number(averageLocationRating).toFixed(1) || "N/A" }}</div>
            </div>
            <div v-if="experience.id !=='all'" class="field-container">
                <span class="field-label__large">Average Playing Time: </span>
                <div>{{ Number(averagePlayingTime).toFixed(0) || "N/A" }}</div>
            </div>
        </div>
        <div class="field-column field-column__large">
            <div class="field-container">
                <span class="field-label">Options:</span>
                <v-select
                    v-model="bookingOptions"
                    multiple
                    :options="BOOKING_OPTIONS"
                    :selectable="(o) => !o.includes('deleted') || bookingOptions.every((bo) => !bo.includes('deleted'))"
                >
                </v-select>
            </div>

            <div class="field-container">
                <span class="field-label">Limit: </span>
                <v-select
                    v-model="limit"
                    :options="[25, 50, 100, 150, 200, 250, 1000, 2500, 10000]"
                    :clearable="false"
                ></v-select>
            </div>
        </div>
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

const ALL_OPTION = {
    name: "All",
    id: "all",
    display: "All",
};

const BOOKING_OPTIONS = ["Only deleted", "Include deleted", "Voucher", "Refund", "Demo", "Consent To Share"];

export default {
    props: {
        locations: {
            type: Array,
            default: () => [],
        },
        xcubes: {
            type: Array,
            default: () => [],
        },
        experiences: {
            type: Array,
            default: () => [],
        },
        playerSum: {
            type: Number,
        },
        averageRating: {
            type: Number,
        },
        averageLocationRating: {
            type: Number,
        },
        averagePlayingTime: {
            type: Number,
        },
        locationRatings: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        DatePicker,
    },
    mounted() {
        this.emitChange();
    },
    data() {
        const newDate = new Date();
        let day = newDate.getDay();
        if (day === 0) day = 7;

        const startDate = new Date(newDate);
        const monday = newDate.getDate() - (day - 1);
        startDate.setDate(monday);
        startDate.setHours(0, 0, 0, 0);

        const endDate = new Date(newDate);
        const sunday = newDate.getDate() + (7 - day);
        endDate.setDate(sunday);
        endDate.setHours(23, 59, 59, 999);

        return {
            BOOKING_OPTIONS,
            selectedLocation: ALL_OPTION,
            selectedXCube: ALL_OPTION,
            experience: ALL_OPTION,
            range: [startDate, endDate],
            limit: 1000,
            demo: false,
            show_filters: false,
            bookingOptions: [],
        };
    },
    watch: {
        selectedLocation() {
            this.selectedXCube = ALL_OPTION;
            this.emitChange();
        },
        selectedXCube() {
            this.emitChange();
        },
        experience() {
            this.emitChange();
        },
        bookingOptions() {
            this.emitChange();
        },
        range() {
            this.emitChange();
        },
        limit() {
            this.emitChange();
        },
    },
    computed: {
        sortedLocations() {
            const result = this.locations.toSorted((a, b) =>
                a.name.localeCompare(b.name)
            );
            return [ALL_OPTION, ...result];
        },
        sortedExperiences() {
            if (!this.experiences) {
                return [ALL_OPTION];
            }
            const result = this.experiences.toSorted((a, b) =>
                a.name.localeCompare(b.name)
            );
            return [ALL_OPTION, ...result];
        },
        sortedXCubes() {
            let xcubes;
            if (
                this.selectedLocation &&
                this.selectedLocation.id !== ALL_OPTION.id
            ) {
                xcubes = this.selectedLocation.xcubes.map((x) => ({ ...x, display: `${x.name} ${x.letter}` }));
            } else {
                xcubes = this.locations.reduce(
                    (acc, l) => acc.concat(l.xcubes),
                    []
                );
                xcubes = xcubes.map((x) => {
                    const location = this.locations.find(
                        (l) => l.id === x.location
                    );
                    return {
                        ...x,
                        display: `${location.name} - ${x.name} ${x.letter}`,
                    };
                });
            }
            const result = xcubes.toSorted((a, b) =>
                a.name.localeCompare(b.name)
            );
            return [ALL_OPTION, ...result];
        },

        //Function to calculate average location rating.

        // averageLocationRating() {
        //   if (this.locationRatings.length === 0) return null;
        //   const totalRating = this.locationRatings.reduce((acc, rating) => acc + rating, 0);
        //   return (totalRating / this.locationRatings.length).toFixed(2);
        // }
    },
    methods: {
        emitChange() {
            const filter = {
                location: this.selectedLocation,
                xcube: this.selectedXCube,
                experience: this.experience,
                options: this.bookingOptions,
                startDate: this.range[0],
                endDate: this.range[1],
                limit: this.limit,
            };
            this.$emit("change", filter);
        },

        /**
         * Method that checks if the checkbox is true or false and adjusts the boolean accordingly.
         * This is necessary for the modal popup.
         * @returns {boolean}
         */
        showFilterModal() {
            return this.show_filters ? false : true;
        },

        /**
         * Method that closes the filters modal.
         * @returns {void}
         */
        closeFilterModal() {
            if (!this.showFilterModal()) {
                this.show_filters = false;
            }
        },
    },
};
</script>

<style lang="scss">
@import "../../colors";

.filter-container {
    display: flex;
    justify-content: flex-start;
    background-color: rgba($color-background, 0.71);
    flex-wrap: wrap;
    border: 1px solid $color-primary;
    border-radius: 0.5rem;
    align-items: center;
    padding: 0.5rem;
}

.vs__search {
    min-width: 100px;
}

.field-column {
    flex-basis: 250px;
    min-width: 200px;
    max-width: 300px;
    &__large {
        flex-basis: 400px;
        min-width: 300px;
        max-width: 500px;
        .vs__dropdown-menu {
            min-width: 300px;
        }
    }
    &__xlarge {
        flex-basis: 500px;
        min-width: 400px;
        max-width: 600px;
        .vs__dropdown-menu {
            min-width: 400px;
        }
    }
}

.field-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.5rem;
    .field-label {
        width: 75px;
        padding-right: 1rem;
        text-align: right;
        &__large {
            width: 175px;
        }
    }
}

</style>