<template>
    <div>
        <div v-if="!loading" class="applications">
            <h1 v-if="app?.experience?.name">{{ app.experience.name }}</h1>
            <h1 v-else>#{{ app.id }} Untitled</h1>
            <div class="table-wrapper">
            <table>
                <thead class="sticky-header">
                    <tr>
                        <th>Location</th>
                        <th>X-CUBE</th>
                        <th v-for="version of allVersions" :key="version">
                            v{{ version }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="xcube of allXCubes" :key="xcube.id">
                        <td>{{ xcube.location.name }}</td>
                        <td> {{ xcube.name }}</td>
                        <td v-for="version of allVersions" :key="version">
                            <div v-if="getStatus(xcube.id, version)">
                                <label class="switch">
                                    <input
                                        type="checkbox"
                                        :checked="
                                            getStatus(xcube.id, version) === 'active'
                                            || getStatus(xcube.id, version) === 'pending-active'
                                        "
                                        @click="toggleStatus(xcube.id, version)"
                                    />
                                    <span class="slider round"></span>
                                </label>
                                <span
                                    class="dot"
                                    :style="{
                                        backgroundColor: getStatusColor(
                                            getStatus(xcube.id, version)
                                        ),
                                    }"
                                ></span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div>
        <spinner :loading="loading" text="Loading applications..."></spinner>
    </div>
</template>

<script>
import api from "../../../api/api";
import Spinner from "../../shared/Spinner.vue";

export default {
    components: {
        Spinner,
    },
    methods: {
        getStatusColor(status) {
            const colorMap = {
                active: "mediumseagreen",
                "pending-active": "yellow",
                downloading: "orange",
                extracting: "orange",
                downloading_error: "tomato",
                extracting_error: "tomato",
                inactive: "dodgerblue",
            };
            return colorMap[status] || "";
        },
        getHasVersion(xcubeId, version) {
            return this.app?.x_cube_applications?.some(
                (x) => x.version === version && x.x_cube?.id === xcubeId
            );
        },
        getStatus(xcubeId, version) {
            const result = this.app?.x_cube_applications?.find(
                (x) => x.version === version && x.x_cube?.id === xcubeId
            );
            return result?.status;
        },
        async toggleStatus(xcubeId, version) {
            const xapp = this.app.x_cube_applications.find((x) => x.version == version && x.x_cube.id === xcubeId);
            if (!xapp) return;
            const newStatus =
                xapp.status === "active" ? "inactive" : "pending-active";
            xapp.status = newStatus;
            api.updateXCubeApplication(xapp.id, { status: newStatus });
        },
    },

    data() {
        return {
            app: null,
            loading: false,
        };
    },
    computed: {
        allVersions() {
            if (this.app?.x_cube_applications?.length > 0) {
                const set = this.app?.x_cube_applications?.reduce(
                    (acc, x) => acc.add(x.version),
                    new Set()
                );
                const list = Array.from(set);
                return list.toSorted((a, b) => (a > b ? -1 : 1));
            }
            return [];
        },
        allXCubes() {
            if (this.app?.x_cube_applications?.length > 0) {
                const set = this.app?.x_cube_applications?.reduce((acc, x) => {
                    if (!x.x_cube) {
                        return acc;
                    }
                    if (acc.some((y) => y.id === x.x_cube.id)) {
                        return acc;
                    }
                    return [...acc, x.x_cube];
                }, []);
                const list = Array.from(set);
                return list.toSorted((a, b) => {
                  const cf = (x) => `${x.location.name}-${x.name}`;
                  return cf(a) > cf(b) ? 1 : -1;
                });
            }
            return [];
        },
    },
    created: async function () {
        this.loading = true;
        this.app = await api.getApplication(this.$route.params.application_id);
        this.loading = false;
    },
};
</script>

<style scoped lang="scss">
.applications {
    text-align: left;
}

.table-wrapper {
  width: calc(100vw - 10px);
  height: calc(100vh - 200px);
  overflow: auto;
  margin: auto;
}

table {
    border-collapse: collapse;
    width: 100%;
    tr {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        th,
        td {
            padding: 5px;
            font-size: 16px;
            vertical-align: top;
        }
        th {
            background-color: transparent;
        }
    }
}

.sticky-header {
    position: sticky;
    top: 0;
    background-color: black;
    z-index: 2;
}

.dot {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    border-radius: 10px;
    margin-right: 5px;
}
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 20px;
    vertical-align: -2px;
    margin: 0 10px 0 0;
    border-radius: 10px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: tomato;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 10px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: mediumseagreen;
}

input:focus + .slider {
    box-shadow: 0 0 1px mediumseagreen;
}

input:checked + .slider:before {
    transform: translateX(30px);
}

.visible-sm {
    display: none;
}

.text-center {
    text-align: center;
}

@media screen and (max-width: 1024px) {
    .visible-sm {
        display: block;
    }
    .center-screen {
        position: fixed;
        color: #e8de16;
        text-align: center;
        top: 50%;
        left: 50%;
        margin-right: -100px;
        transform: translate(-50%, -50%);
    }
    .first-word {
        text-align: center;
        display: block;
    }
}
</style>